import React, { useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function DocumentPreview({ document }) {
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <Document
      file={document.url}
      onLoadSuccess={onDocumentLoadSuccess}
      loading=" "
    >
      <Page pageNumber={1} width={140} loading=" " renderAnnotationLayer={false} />
    </Document>
  )
}

export default DocumentPreview
